import { defineAsyncComponent } from "vue";

const RequestResetUserPassword = defineAsyncComponent(
  () => import("./RequestResetUserPassword.vue"),
);

const RequestResetUserPasswordByAuth = defineAsyncComponent(
  () => import("./RequestResetUserPasswordByAuth.vue"),
);

export { RequestResetUserPassword, RequestResetUserPasswordByAuth };
